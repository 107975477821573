<template>
  <div class="branding-page">
    <div style="margin-top: 20px">
      <div>
        <div class="card mb-3 sidemenu profile-card" v-loading="loading">
          <el-tabs
            v-model="activeName"
            class="demo-tabs"
            @tab-remove="removeTab"
            @tab-click="previewTemplateCalling()"
          >
            <el-tab-pane label="Profile" name="profile">
              <div class="logo-container">
                <span class="mt-1 ml-1 logo-name-sty">Company Logo</span>
                <div>
                  <img
                    v-loading="loading"
                    v-if="this.imgData"
                    :key="someUniqueKey"
                    :src="this.imgData"
                    height="100"
                    width="200"
                    class="avatar ml-1"
                  />

                  <img
                    v-loading="loading"
                    v-else
                    :src="logo"
                    height="100"
                    width="200"
                    class="avatar mt-1 ml-1"
                  />
                  <span v-if="getSingleCompanyLogo">
                    <!-- show delete icon -->
                    <el-button
                      style="
                        background-color: ivory;
                        max-width: 27px;
                        margin-left: -8px;
                      "
                      type="plain"
                      size="mini"
                      circle
                      @click="deleteLogo()"
                    >
                      <img width="12px" src="@/assets/Trash.svg" />
                    </el-button>
                  </span>
                </div>
                <el-button type="primary" @click="openLogoDialog">
                  {{ getSingleCompanyLogo ? "Change Logo" : "Add Logo" }}
                </el-button>
              </div>
              <el-divider></el-divider>
              <div v-if="this.brandingInfo.slug">
                <el-alert :closable="false" type="warning alert">
                  <template slot="title"
                    >Application users can login by using below URL</template
                  >
                  <template>
                    <span>
                      <el-link type="primary">
                        {{ this.copyCompanyLink }}
                      </el-link>
                    </span>
                    <el-button
                      icon="el-icon-copy-document"
                      @click="copyURL"
                      size="mini"
                      class="ml-1"
                    ></el-button>
                  </template>
                </el-alert>
              </div>
              <div v-else>
                <el-alert :closable="false" type="warning alert">
                  <template slot="title">
                    You can give login to your Individual & Business entity
                    contacts by activating the below option.
                  </template>
                  <template>
                    <el-button
                      icon="el-icon-check"
                      @click="updateCompanyDetails"
                      size="mini"
                      class="ml-1"
                      type="primary"
                      >Activate</el-button
                    >
                  </template>
                </el-alert>
              </div>
              <el-divider></el-divider>
              <el-collapse v-model="activeMenu" @change="handleChange">
                <div class="mr-1 ml-1 mb-1">
                  <el-collapse-item
                    title="Company Information"
                    name="1"
                    class="fw-bold"
                  >
                    <el-row :span="24">
                      <el-col :span="10" class="mt-1">
                        <span class="ml-2">Company Name</span>
                        <el-input
                          type="text"
                          v-model="brandingInfo.name"
                          :readonly="checkReadonly()"
                          placeholder="Enter Company Name"
                          autocomplete="off"
                          class="input-field ml-2"
                        ></el-input>
                      </el-col>
                      <el-col :span="10" :offset="2" class="mt-1">
                        <span>Legal Name</span>
                        <el-input
                          type="text"
                          v-model="brandingInfo.legal_name"
                          :readonly="checkReadonly()"
                          placeholder="Enter Company Legal Name"
                          autocomplete="off"
                          class="input-field"
                        ></el-input>
                      </el-col>
                    </el-row>
                    <el-row :span="24">
                      <el-col :span="10" class="mt-1">
                        <span class="ml-2">Address</span>
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 2, maxRows: 4 }"
                          v-model="brandingInfo.address"
                          placeholder="Enter Company Address"
                          autocomplete="off"
                          class="input-field ml-2"
                        ></el-input>
                      </el-col>
                      <el-col :span="10" :offset="2" class="mt-1">
                        <span>Domain Name</span>
                        <el-input
                          type="text"
                          @blur="checkDomain"
                          @change="isValidDomain = false"
                          v-model="brandingInfo.email_domain"
                          placeholder="Enter Company Domain"
                          autocomplete="off"
                          class="input-field"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-collapse-item>
                </div>
                <div class="mr-1 ml-2">
                  <el-collapse-item
                    title="Email Color Customization"
                    name="2"
                    class="fw-bold"
                  >
                    <div class="mr-1 mb-1">
                      <div
                        class="btn-colors-2 mt-1"
                        style="display: flex; flex-wrap: wrap; gap: 15px"
                      >
                        <div
                          class="color-box"
                          style="flex-basis: 33.33%; box-sizing: border-box"
                        >
                          <span>Theme Button color</span>
                          <el-input
                            type="color"
                            class="fw-bold"
                            v-model="brandingInfo.button.background"
                            style="width: 100%"
                          ></el-input>
                          <div class="tooltip-text">Select a color</div>
                        </div>
                        <div
                          class="color-box"
                          style="flex-basis: 33.33%; box-sizing: border-box"
                        >
                          <span>Theme Button Text color</span>
                          <el-input
                            type="color"
                            class="fw-bold"
                            v-model="brandingInfo.button.color"
                            style="width: 100%"
                          ></el-input>
                          <div class="tooltip-text">Select a color</div>
                        </div>
                      </div>
                      <!-- <span>Theme Icon color</span>
                        <el-select
                          v-model="brandingInfo.icon_color"
                          placeholder="Select icon color"
                        >
                          <el-option
                            v-for="iconColor in iconColors"
                            :key="iconColor.color"
                            :label="iconColor.label"
                            :value="iconColor.color"
                          >
                          </el-option>
                        </el-select> -->

                      <div
                        class="btn-colors mt-1"
                        style="display: flex; flex-wrap: wrap; gap: 15px"
                      >
                        <div
                          class="color-box"
                          style="flex-basis: 33.33%; box-sizing: border-box"
                        >
                          <span>Theme Banner color</span>
                          <el-input
                            type="color"
                            class="fw-bold"
                            v-model="brandingInfo.banner.background"
                            style="width: 100%"
                          ></el-input>
                          <div class="tooltip-text">Select a color</div>
                        </div>
                        <div
                          class="color-box"
                          style="flex-basis: 33.33%; box-sizing: border-box"
                        >
                          <span>Theme Banner Text color</span>
                          <el-input
                            type="color"
                            class="fw-bold"
                            v-model="brandingInfo.banner.color"
                            style="width: 100%"
                          ></el-input>
                          <div class="tooltip-text">Select a color</div>
                        </div>
                        <div
                          class="color-box"
                          style="flex-basis: 33.33%; box-sizing: border-box"
                        >
                          <span>Theme Button type</span>
                          <el-select
                            v-model="brandingInfo.button.button_type"
                            placeholder="Select button type"
                            clearable
                            style="width: 100%"
                          >
                            <el-option
                              v-for="type in buttonTypes"
                              :key="type.value"
                              :label="type.label"
                              :value="type.value"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>

                    <div class="mr-2 ml-2 mt-1">
                      <span>Email Footer Text</span>
                      <el-input
                        type="textarea"
                        rows="4"
                        v-model="brandingInfo.email_footer_text"
                      ></el-input>
                    </div>
                    <div class="mr-2 ml-2 mt-1">
                      <span>About your company</span>
                      <el-input
                        type="textarea"
                        rows="4"
                        v-model="brandingInfo.about_company_text"
                      ></el-input>
                    </div>
                    <div class="ml-2 mt-1">
                      <el-button type="primary" @click="openPreviewDialog" plain
                        >Preview</el-button
                      >
                    </div>
                    <el-divider></el-divider>
                    <div class="mr-2 ml-2 mt-1">
                      <el-row :span="24">
                        <el-col :span="10" class="mt-1">
                          <span></span>
                          <el-checkbox
                            v-model="brandingInfo.session_info.timeOut"
                            label="true"
                            >Do you want the session to expire when the system
                            is idle?</el-checkbox
                          >
                          <div
                            :span="10"
                            :offset="2"
                            class="mt-1"
                            v-if="brandingInfo.session_info.timeOut"
                          >
                            <span style="margin-left: 12px"
                              >Timeout (Minutes)</span
                            >
                            <el-input
                              type="number"
                              v-model="brandingInfo.session_info.timeOutSeconds"
                              class="input-field ml-2"
                              placeholder="Timeout (Minutes)"
                              :min="5"
                              :max="100"
                              @blur="checkInput()"
                            ></el-input>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </el-collapse-item>
                </div>
                <div class="mr-1 ml-2 mt-1">
                  <el-collapse-item
                    title="App Notification Settings"
                    name="3"
                    class="fw-bold"
                  >
                    <!-- <div style="display: flex;"> -->
                    <div class="mr-2 mt-1 color-box">
                      <h4>Entity :</h4>
                      <el-checkbox-group
                        v-model="appNotificationSettings.selected_settings"
                      >
                        <el-checkbox label="ENTITY_ADDED">Added</el-checkbox>
                        <el-checkbox label="ENTITY_UPDATED"
                          >Updated</el-checkbox
                        >
                        <el-checkbox label="ENTITY_DELETED"
                          >Deleted</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                    <div class="mr-2 mt-1 color-box">
                      <h4>Form Builder :</h4>
                      <el-checkbox-group
                        v-model="appNotificationSettings.selected_settings"
                      >
                        <el-checkbox label="FORM_FILLED">Filled</el-checkbox>
                        <el-checkbox label="FORM_APPROVED"
                          >Approved</el-checkbox
                        >
                        <el-checkbox label="FORM_REJECTED"
                          >Rejected</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                    <div class="mr-2 mt-1 color-box">
                      <h4>Notifications :</h4>
                      <el-checkbox-group
                        v-model="appNotificationSettings.selected_settings"
                      >
                        <el-checkbox label="MAIL_SENT">Email Sent</el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <!-- </div> -->
                  </el-collapse-item>
                </div>
                <div class="mr-1 ml-2 mt-1">
                  <el-collapse-item
                    title="Theme Palette Settings"
                    name="4"
                    class="fw-bold"
                  >
                    <div class="mr-1 ml-1 mb-1">
                      <el-collapse-item
                        title="Primary Settings"
                        name="5"
                        class="mt-20"
                      >
                        <div style="display: flex; flex-wrap: wrap; gap: 15px">
                          <div
                            class="color-box mt-1"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Theme Primary color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.dialog_header_color"
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box mt-1"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Theme Primary Text color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.dialog_header_text_color"
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Topbar color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.menubackground"
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Topbar Text color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.topbar_text_color"
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Application Background Color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="
                                brandingInfo.application_background_color
                              "
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Default Font type</span>
                            <el-select
                              placeholder="Select default Font"
                              v-model="brandingInfo.default_font_type"
                              style="width: 100%"
                              clearable
                            >
                              <el-option
                                v-for="(option, index) of getAvailableFontTypes"
                                :value="option.value"
                                :label="option.name"
                                :key="index"
                              >
                                <p :style="'font-family:' + option.value">
                                  {{ option.name }}
                                </p>
                              </el-option>
                            </el-select>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>
                              Default Font Size
                              <el-tooltip
                                content="The font size can currently only be applied in CKEditor."
                              >
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </span>
                            <el-select
                              placeholder="Select default font size"
                              v-model="brandingInfo.default_font_size"
                              style="width: 100%"
                              clearable
                            >
                              <el-option
                                v-for="(option, index) of getAvailableFontSizes"
                                :value="option + 'px'"
                                :label="option"
                                :key="index"
                                :style="{
                                  minHeight:
                                    option > 36 ? option + 'px' : '36px',
                                }"
                              >
                                <p
                                  :style="{
                                    fontSize: option + 'px',
                                    lineHeight: option + 'px',
                                    margin: 0,
                                  }"
                                >
                                  {{ option }}
                                </p>
                              </el-option>
                            </el-select>
                          </div>
                        </div>

                        <div class="mt-1 ml-1 fw-bold">
                          Sidemenu Style Settings
                        </div>
                        <div class="mt-1 ml-1 mb-1">
                          <el-switch
                            v-model="brandingInfo.styled_sidemenu"
                            active-text="Color Filled"
                            inactive-text="Default Style"
                          >
                          </el-switch>
                        </div>

                        <div
                          v-if="brandingInfo.styled_sidemenu"
                          style="display: flex; flex-wrap: wrap; gap: 15px"
                        >
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Menu Background Color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.sidemenu_background"
                              style="width: 100%"
                            ></el-input>
                          </div>
                          <div
                            class="color-box"
                            style="flex-basis: 33.33%; box-sizing: border-box"
                          >
                            <span>Menu Text Color</span>
                            <el-input
                              type="color"
                              class="fw-bold"
                              v-model="brandingInfo.sidemenu_text"
                              style="width: 100%"
                            ></el-input>
                          </div>
                        </div>
                      </el-collapse-item>
                    </div>

                    <div class="mr-1 ml-2">
                      <el-collapse-item
                        title="Menu Settings"
                        name="6"
                        class="fw-bold"
                      >
                        <div class="mr-2 mt-1">
                          <span>Menu Displaying Type</span>
                          <br />
                          <el-radio-group v-model="brandingInfo.menuType">
                            <el-row>
                              <el-radio label="Vertical"></el-radio>
                              <el-radio label="Horizontal"></el-radio>
                            </el-row>
                          </el-radio-group>
                        </div>
                        <div class="mr-2 mt-1">
                          <span>Display Menu Icon </span>
                          <br />
                          <el-radio-group v-model="brandingInfo.menuIcon">
                            <el-row>
                              <el-radio :label="true">Yes</el-radio>
                              <el-radio :label="false">NO</el-radio>
                            </el-row>
                          </el-radio-group>
                        </div>
                      </el-collapse-item>
                    </div>
                  </el-collapse-item>
                </div>
                <div class="mr-1 ml-2 mt-1">
                  <el-collapse-item
                    title="Page View Settings"
                    name="5"
                    class="fw-bold"
                  >
                    <div style="margin-top: 20px">
                      <el-radio-group
                        v-model="brandingInfo.form_template_view"
                        size="mini"
                      >
                        <el-radio label="1">Table View</el-radio>
                        <el-radio label="2">Card View</el-radio>
                      </el-radio-group>
                    </div>
                  </el-collapse-item>
                </div>
                <div class="mr-1 ml-2 mt-1">
                  <el-collapse-item
                    title="Formbuilder Settings"
                    name="7"
                    class="fw-bold"
                  >
                    <div style="margin-top: 20px">
                      You can give the name for HOLD status of approval forms here:
                        <br>
                        <el-input
                          type="text"
                          v-model="brandingInfo.formbuilder_approval_hold_name"
                          placeholder="Enter Name"
                          autocomplete="off"
                        ></el-input>
                    </div>
                  </el-collapse-item>
                </div>
              </el-collapse>
              <!-- <el-divider></el-divider>
                        <div class="mr-2 ml-2 mt-1">
                            <span class="fw-bold">Email Domain Integration</span>
                            <div>
                                <el-button  
                                    @click="openDomainValidationPopup()" 
                                    size="mini"
                                    class="pr-5 pl-5 domain-validate-button">
                                        Validate my domain
                                </el-button>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="By validating your domain you can send emails from your own domain."
                                    placement="right"
                                >
                                    <el-button
                                    plain
                                    icon="el-icon-info"
                                    class="add-page-top"
                                    ></el-button>
                                </el-tooltip>
                            <div>
                            <el-select
                                v-model="EmailInput"
                                v-if="brandingInfo.isDomainValidated"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                clearable
                                size="medium"
                                placeholder="Enter the email">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                            </el-select>
                            </div>
                            </div>
                        </div> -->
              <el-divider></el-divider>
              <div style="float: left" class="ml-2 mb-15">
                <el-button @click="resetCompanyDetails" plain>Cancel</el-button>
                <el-button type="primary" @click="submitForm('form')"
                  >Save Changes</el-button
                >
              </div>
            </el-tab-pane>
            <el-tab-pane
              v-for="(step, index) of getAllSteps"
              :key="index"
              :label="step.templateInfo.name"
              :name="step.templateInfo.slug"
              :closable="true"
            >
              <PreviewTemplate
                :templateData="step.templateInfo"
                :has-next="false"
                :entityDataId="entityDataId"
                :companyProfile="true"
                @event="handler"
                :isFromSenderSide="true"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <dialog-component
      :title="editTemplateName"
      :visible="editTemplateDataDialog"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      @before-close="closeEditTemplateDataPopUp"
    >
      <PreviewTemplate
        :templateData="editTemplateInfo"
        :has-next="false"
        :entityDataId="entityDataId"
        :is-execute="true"
        :is-edit="true"
        :companyProfileEdit="true"
        :companyEntityId="
          this.getCompanyEntity && this.getCompanyEntity._id
            ? this.getCompanyEntity._id
            : null
        "
        @companyProfileEditEvent="companyProfileEditEvent"
        @close="editTemplateDataDialog = false"
      />
    </dialog-component>
    <dialog-component
      :title="'Preview Theme'"
      :visible="previewDialog"
      :width="getIsMobile ? '100%' : '55%'"
      :contianerMaxHeight="'50%'"
      @before-close="previewDialog = false"
      :isShowFooter="false"
    >
      <div>
        <div class="text-center mb-2 mt-1">
          Preview:
          <el-radio-group v-model="preview">
            <el-radio-button label="EMAIL"></el-radio-button>
            <el-radio-button label="EMPLOYEE LOGIN"></el-radio-button>
          </el-radio-group>
        </div>
        <div>
          <div v-if="preview == 'EMAIL'">
            <EmailPreview
              :isFromPreview="true"
              :imgData="imgData"
              :companyData="brandingInfo"
              :logo="logo"
            ></EmailPreview>
          </div>
          <div v-else>
            <ContactLoginPage
              :isFromPreview="true"
              :companyData="brandingInfo"
              :imgData="imgData"
              :logo="logo"
            >
            </ContactLoginPage>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </dialog-component>
    <dialog-component
      :title="'Change Company Logo'"
      :visible="logoDialog"
      :width="getIsMobile ? '100%' : '50%'"
      :containerMaxHeight="'80%'"
      @before-close="closeDialog"
    >
      <el-row>
        <el-col>
          <div class="mb-1">
            <vue-anka-cropper
              class="upload-file mt-1"
              :options="{
                aspectRatio: false,
                closeOnSave: true,
                cropArea: 'box',
                croppedHeight: 1000,
                croppedWidth: 1000,
                cropperHeight: false,
                dropareaMessage:
                  ' Drag & Drop  file here or use the button below.',
                frameLineDash: [1, 1000],
                frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
                handleFillColor: 'rgba(255, 255, 255, 0.2)',
                handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
                handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
                handleSize: 5,
                handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
                layoutBreakpoint: 40,
                maxCropperHeight: 400,
                maxFileSize: 8000000,
                overlayFill: 'rgba(0, 0, 0, 0.5)',
                previewOnDrag: true,
                previewQuality: 0.65,
                resultQuality: 0.8,
                resultMimeType: 'image/jpeg',
                resultMimeType: 'image/png',
                resultMimeType: 'image/jpg',
                resultMimeType: 'image/gif',
                selectButtonLabel:
                  'Select File Supported : JPG, PNG, JPEG, GIF',

                showPreview: true,
                skin: 'light',
                uploadData: {},
                uploadTo: false,
              }"
              name="logo"
              @cropper-error="fileError"
              @cropper-preview="uploadFile"
              v-loading="loading"
              @cropper-file-selected="uploadFile"
              @cropper-saved="onUpdate"
              accept="image/jpeg,image/png,image/jpg,image/gif"
            >
            </vue-anka-cropper>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onUpdate">Confirm</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="domainValidationDialog"
      title="Validate New Domain"
      @before-close="handleDomainValidationDialogClose"
      containerMaxWidth="70%"
    >
      <EmailIntegration></EmailIntegration>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import appConfig from "@/config/app";
import PreviewTemplate from "./../templates/PreviewTemplate";
import ContactLoginPage from "@/views/contactLogin/loginPage.vue";
import EmailPreview from "@/components/account/EmailPreview.vue";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import fonts from "@/constants/fonts.js";
export default {
  name: "Branding",
  components: {
    PreviewTemplate,
    vueAnkaCropper,
    ContactLoginPage,
    EmailPreview,
    EmailIntegration: () =>
      import("@/components/notifications/emailIntegration.vue"),
  },
  data() {
    return {
      activeMenu: "0",
      copyCompanyLink: "",
      selectDomain: 3,
      EmailInput: "",
      preview: "EMAIL",
      logoDialog: false,
      previewDialog: false,
      buttondisale: true,
      appNotificationSettings: {
        selected_settings: [
          "ENTITY_ADDED",
          "FORM_FILLED",
          "MAIL_SENT",
          "FORM_APPROVED",
          "FORM_REJECTED",
        ],
      },
      domainValidationDialog: false,
      brandingInfo: {
        name: "",
        legal_name: "",
        address: "",
        authorized_signatures: [],
        logo: "",
        avatar: "",
        email_logo: "",
        icon_color: "",
        banner: {
          background: "",
          color: "",
        },
        button: {
          background: "",
          color: "",
          button_type: "",
        },
        about_company_text: "",
        email_footer_text: "",
        email_domain: "",
        enforce_domain: false,
        session_info: {
          timeOut: false,
          timeOutSeconds: 5,
          accessToken: 1800,
          refreshToken: 3600,
        },
        menuType: "Vertical",
        menuIcon: true,
        form_template_view: "1",
        formbuilder_approval_hold_name: 'HOLD',
        menubackground: "#1B487E",
        application_background_color: "",
        topbar_text_color: "",
        default_font_type: "",
        default_font_size: "",
        styled_sidemenu: false,
        sidemenu_background: "",
        sidemenu_text: "",
        dialog_header_color: "",
        dialog_header_text_color: "",
      },
      iconColors: [
        {
          color: "White",
          label: "White",
        },
        {
          color: "Black",
          label: "Black",
        },
      ],
      companyInfo: {},
      imgData: "",
      upLoadEffect: false,
      isTemplatePreview: true,
      isValidDomain: false,
      loading: false,
      templateDataId: "",
      logoError: "",
      logo: "",
      rules: {
        name: [
          {
            required: true,
            message: "Company Name is required",
            trigger: "blur",
          },
        ],
        legal_name: [
          {
            required: true,
            message: "Company Legal Name is required",
            trigger: "blur",
          },
        ],
      },
      currentSlug: null,
      entityDataId: null,
      //   getCurrentStep: { templateInfo: {}, has_next: false },
      activeName: "profile",
      editTemplateDataDialog: false,
      editTemplateInfo: null,
      editTemplateName: "",
      buttonTypes: [
        {
          value: "RECTANGLE",
          label: "Rectangle",
        },
        {
          value: "ROUND",
          label: "Round",
        },
      ],
      someUniqueKey: 0,
    };
  },
  computed: {
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getWorkspaceList",
      "getAuthenticationDetails",
      "getUserType",
      "getActiveWorkspace",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", ["getTemplatesData"]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
    ]),

    ...mapGetters("entities", [
      "getCompanyEntity",
      "getEntityDataUpdateStatus",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    logoUploadUrl() {
      return appConfig.API_URL + "/company/logo";
    },
    getAvailableFontTypes() {
      return fonts.fontTypes;
    },
    getAvailableFontSizes() {
      return fonts.fontSizes;
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    getAllSteps() {
      if (this.getCompanyEntity && this.getCompanyEntity.templates) {
        // this.currentSlug = this.getCompanyEntity.templates[0].templateInfo.slug;
        return this.getCompanyEntity.templates;
      }
      return [];
    },
    slugs() {
      if (this.getCompanyEntity && this.getCompanyEntity.templates) {
        return this.getCompanyEntity.templates.map(
          (step) => step.templateInfo.slug
        );
      }
      return [];
    },
    isCurrentSlugPresent() {
      if (this.slugs && this.slugs.length) {
        if (this.slugs.indexOf(this.currentSlug) === -1) {
          return false;
        }
        return true;
      }
      return true;
    },
    getStepsObject() {
      if (this.getCompanyEntity && this.getCompanyEntity.templates) {
        const data = {};
        this.getCompanyEntity.templates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          data[step.templateInfo.slug] = step;
        });

        return data;
      }
      return {};
    },
  },
  async mounted() {
    if (!this.getCompanyDetails) {
      await this.reloadCompanyDetails();
    }
    if (
      this.getActiveWorkspace.company_id &&
      this.getUserType &&
      this.getUserType.name &&
      (this.getUserType.name == "ADMIN" || this.getUserType.name == "OWNER")
    ) {
      Promise.all([this.getCompanyInfo(), this.getNecessaryInfo()]);
    } else {
      this.$router.push({
        name: "personal-information",
      });
    }
  },
  methods: {
    async reloadCompanyDetails() {
      let id =
        this.getActiveWorkspace &&
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id._id
          ? this.getActiveWorkspace.company_id._id
          : this.getActiveWorkspace.company_id;
      await Promise.all([
        this.$store.dispatch("company/fetchCompany", id),
        this.$store.dispatch("company/fetchCompanyLogo", id),
      ]);
    },
    handleChange() {},
    closeDialog() {
      this.logoDialog = false;
      this.imgData = "";
    },
    checkInput() {
      if (this.brandingInfo.session_info.timeOutSeconds < 5) {
        this.brandingInfo.session_info.timeOutSeconds = 5;
      } else if (this.brandingInfo.session_info.timeOutSeconds > 100) {
        this.brandingInfo.session_info.timeOutSeconds = 100;
      }
    },
    openLogoDialog() {
      this.logoDialog = true;
    },
    openPreviewDialog() {
      this.previewDialog = true;
    },
    generateLinkForCompany() {
      let url = appConfig.APP_URL;
      url += `/ap/signin?company=${this.brandingInfo.slug}`;
      this.copyCompanyLink = url;
    },
    copyURL() {
      navigator.clipboard.writeText(this.copyCompanyLink);
      this.$message({
        message: "Link copied successfully!",
        type: "success",
      });
    },
    resetImgData() {
      this.imgData = "";
    },
    async removeTab(targetName) {
      this.$confirm("Are you sure to delete?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          //   done()
          this.loading = true;
          let templateTabs = this.getCompanyEntity.templates;
          //let primaryFieldsOfTemplate = this._.filter(
          //  templateTabs,
          //  function (o) {
          //    return
          //  }
          //);
          let removedTemplate = templateTabs.find(
            (o) => o.templateInfo.slug === targetName
          );
          let templateNameWithSpaces = removedTemplate
            ? removedTemplate.templateInfo.name
            : targetName;

          let primaryFieldsOfTemplate = templateTabs.filter(
            (o) => o.templateInfo.slug != targetName
          );
          let params = {
            name: this.getCompanyEntity.name,
            description: this.getCompanyEntity.description,
            templates: primaryFieldsOfTemplate,
            id: this.getCompanyEntity._id,
            primaryFields: this.getCompanyEntity.primaryFields,
            entity_type: "COMPANY",
          };
          await this.$store.dispatch("entities/updateEntityById", params);
          if (this.getEntityDataUpdateStatus) {
            this.activeName = "profile";
            this.loading = false;

            this.getCompanyInfo();
            this.getNecessaryInfo();
            this.$notify({
              title: "Success",
              message: templateNameWithSpaces + " Template Deleted",
              type: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              message: "Error in updating Entity",
              type: "error",
            });
            this.loading = false;
          }
        })
        .catch(() => {
          // catch error
        });
    },
    closeEditTemplateDataPopUp() {
      this.editTemplateInfo = null;
      this.editTemplateName = "";
      //   this.getCurrentStep.has_next = false;
      this.editTemplateDataDialog = false;
    },
    companyProfileEditEvent() {
      this.loading = true;
      this.closeEditTemplateDataPopUp();
      this.getCompanyInfo();
      this.getNecessaryInfo();
      //   setTimeout(() => {
      //     this.activeName = params.activeName;
      //   }, 2000);
    },
    async previewTemplateCalling() {
      if (this.activeName && this.activeName != "profile") {
        this.getNecessaryInfo();
      }
    },
    handler(params) {
      this.editTemplateInfo = params.templateData;
      this.editTemplateName = "Edit " + params.templateData.name;
      //   this.getCurrentStep.has_next = false;
      this.editTemplateDataDialog = true;
    },
    getStepUrl(step) {
      this.currentSlug = step.templateInfo.slug;
      this.getCurrentStep.templateInfo = step.templateInfo;
      this.getCurrentStep.has_next = false;
    },
    checkReadonly() {
      if (this.getUserType != undefined) {
        return !(
          this.getUserType.name == "OWNER" || this.getUserType.name == "ADMIN"
        );
      } else {
        return true;
      }
    },
    async getCompanyInfo() {
      this.loading = true;
      try {
        // let id =
        //     this.getActiveWorkspace &&
        //     this.getActiveWorkspace.company_id &&
        //     this.getActiveWorkspace.company_id._id ?
        //     this.getActiveWorkspace.company_id._id :
        //     this.getActiveWorkspace.company_id;
        // await this.$store.dispatch("company/fetchCompany", id);
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
          if (this.getCompanyDetails && !this.getCompanyDetails.session_info) {
            this.$set(this.brandingInfo, "session_info", {
              timeOut: false,
              timeOutSeconds: 5,
              accessToken: 1800,
              refreshToken: 3600,
            });
          }
          let companyName = this.brandingInfo.legal_name
            ? this.brandingInfo.legal_name
            : this.brandingInfo.name;
          this.$set(this.brandingInfo, "legal_name", companyName);
          this.logo = `${appConfig.S3_BUCKET_URL}/${this.brandingInfo.logo}`;
          if (this.brandingInfo?.app_notification_settings) {
            this.appNotificationSettings = {
              ...this.brandingInfo?.app_notification_settings,
            };
          } else {
            this.appNotificationSettings = {
              selected_settings: [
                "ENTITY_ADDED",
                "FORM_FILLED",
                "MAIL_SENT",
                "FORM_APPROVED",
                "FORM_DECLINED",
              ],
            };
          }
        }
        this.generateLinkForCompany();
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchCompanyEntity");
      if (this.getCompanyEntity) {
        this.loading = false;
        this.entityDataId = this.getCompanyEntity.entity_data
          ? this.getCompanyEntity.entity_data._id
          : null;
      } else {
        this.loading = false;
      }
      if (this.getAllSteps && this.getAllSteps.length) {
        this.currentSlug = this.getAllSteps[0].templateInfo.slug;
        this.getCurrentStep =
          this.getStepsObject[this.getAllSteps[0].templateInfo.slug];
        await this.getTemplateData(
          this.getCurrentStep.templateInfo._id,
          this.entityDataId
        );
      }
    },
    async getTemplateData(templateId, entityDataId) {
      bus.$emit("companyProfile", {
        entityDataId: entityDataId,
        templateId: templateId,
      });
    },
    async submitForm() {
      await this.$store.commit("errors/setErrors", null, {
        root: true,
      });
      if (this.brandingInfo && !this.brandingInfo.name) {
        await this.$store.commit(
          "errors/setErrors",
          {
            company_name: "Company name required",
            critical_error: "Company name required",
          },
          {
            root: true,
          }
        );
        return;
      } else if (this.brandingInfo && !this.brandingInfo.legal_name) {
        await this.$store.commit(
          "errors/setErrors",
          {
            company_legal_name: "Company legal name required",
            critical_error: "Company legal name required",
          },
          {
            root: true,
          }
        );
        return;
      } else if (
        this.brandingInfo &&
        this.isValidDomain &&
        this.brandingInfo.email_domain
      ) {
        await this.$store.commit(
          "errors/setErrors",
          {
            company_domain:
              "Invalid domain name. Please enter a valid domain ex: example.com",
            critical_error: "Invalid domain name. Please enter a valid domain",
          },
          {
            root: true,
          }
        );
        return;
      } else {
        await this.updateCompanyDetails();
        if (this.buttondisale == false) {
          await this.onUpdate();
          this.closeOnSave = true;
          this.outerVisible = false;
          this.upLoadEffect = false;
        }
        window.location.reload();
      }
    },
    async updateCompanyDetails() {
      this.loading = true;
      try {
        this.brandingInfo.slug = this.brandingInfo.legal_name
          .trim()
          .toLowerCase()
          .replace(/\W/gi, "");
        this.brandingInfo["app_notification_settings"] = {
          ...this.appNotificationSettings,
        };
        await this.$store.dispatch("company/updateCompany", this.brandingInfo);
        if (this.getUpdateCompany && this.getUpdateCompany.success) {
          let activeWorkspace = this.getActiveWorkspace;
          activeWorkspace.companyInfo = this.brandingInfo;
          await Promise.all([
            this.reloadCompanyDetails(),
            this.$store.dispatch(
              "auth/fetchUsersWithEmail",
              this.getAuthenticatedUser.email
            ),
          ]);
          if (this.getWorkspaceList) {
            let userWorkspaceList = this.getWorkspaceList;
            let hasIndividual = this.getAuthenticatedUser.workspace.findIndex(
              (u) => u.plan_type == "INDIVIDUAL"
            );
            if (hasIndividual != -1) {
              let individualWorkspace = {};
              if (
                this.getAuthenticatedUser.workspace[hasIndividual].company_id
              ) {
                this.$set(
                  individualWorkspace,
                  "_id",
                  "" +
                    this.getAuthenticatedUser.workspace[hasIndividual]
                      .company_id
                );
              } else {
                this.$set(individualWorkspace, "_id", "0");
              }
              this.$set(individualWorkspace, "name", "INDIVIDUAL");
              userWorkspaceList[hasIndividual]["company_id"] =
                individualWorkspace;
            }
            bus.$emit("workspaceList", userWorkspaceList);
          }
          this.getCompanyInfo();
          localStorage.setItem(
            `companyInfo_${activeWorkspace.companyInfo.id}`,
            JSON.stringify(this.brandingInfo)
          );
          this.$store.commit("auth/setActiveWorkspace", activeWorkspace, {
            root: true,
          });
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Company information updated successfully",
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: this.getErrors.critical_error,
          });
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    uploadFile(file) {
      this.imgData = file;

      // Remove the setTimeout block

      const doc = document.querySelector(".ankaCropper__previewArea");
      this.imgData = doc.querySelector("img").src;

      this.profileData = file;
      this.buttondisale = false;
      this.loading = false;
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async deleteLogo() {
      this.$confirm("Are you sure you want to delete the Company logo?")
        .then(async () => {
          let params = {
            file: "",
            isRemovingProfilePicture: true,
          };
          await this.$store.dispatch("company/updateCompanyLogo", params);
          if (this.getCompanyLogo) {
            this.imgData = null;
            await this.reloadCompanyDetails();
            this.$notify({
              title: "Success",
              message: "Company logo deleted successfully.",
              type: "success",
            });
            window.location.reload();
          } else {
            this.$notify({
              title: "Error",
              message: "Error in deleting logo",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    async onUpdate() {
      this.upLoadEffect = true;
      this.loading = true;
      let file = this.imgData;
      if (file && typeof file == "string") {
        file = this.dataURLtoFile(file, this.brandingInfo._id + ".jpeg");
      }
      var formData = new FormData();
      formData.append("files", file);
      await this.$store.dispatch(
        "s3FileUpload/uploadCompanyLogoToS3",
        formData
      );
      if (this.getCompanyLogoUpdateData) {
        // }
        let params = {
          file: this.getCompanyLogoUpdateData,
        };
        await this.$store.dispatch("company/updateCompanyLogo", params);
        if (this.getCompanyLogo) {
          this.brandingInfo = this.getCompanyLogoUpdateData;
          this.outerVisible = false;
          this.upLoadEffect = false;

          this.$notify({
            title: "Success",
            message: "Company Logo Updated Successfully",
            type: "success",
          });
          await this.reloadCompanyDetails();
          this.outerVisible = false;
          this.upLoadEffect = false;
          this.buttondisale = true;
          this.loading = false;
          window.location.reload();
        } else {
          this.loading = false;
          this.$notify({
            title: "Error",
            message: "Error while updating Logo",
            type: "error",
          });
          this.upLoadEffect = false;
        }
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: "Error while uploading Logo",
          type: "error",
        });
        this.upLoadEffect = false;
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {
        type: mime,
      });
    },
    async checkDomain() {
      let domainPattern =
        /^([A-Z|a-z|0-9](-|_){0,1})+((\.){0,1}[A-Z|a-z|0-9]){2,4}\.[a-z]{2,}$/;
      this.isValidDomain = !domainPattern.test(this.brandingInfo.email_domain);
      if (this.isValidDomain && this.brandingInfo.email_domain) {
        await this.$store.commit(
          "errors/setErrors",
          {
            company_domain:
              "Invalid domain name. Please enter a valid domain ex: example.chandleLogoSuccessom",
            critical_error: "Invalid domain name. Please enter a valid domain",
          },
          {
            root: true,
          }
        );
      } else {
        await this.$store.commit("errors/setErrors", null, {
          root: true,
        });
      }
    },
    handleLogoSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.loading = false;
      this.$notify.success({
        title: "success",
        message: "Logo Updated Successfully",
      });
      this.getCompanyInfo();
    },
    handleLogoError(res) {
      res = JSON.parse(res.message);
      if (!res.success) {
        this.logoError = res.message;
      }
      this.loading = false;
    },
    resetCompanyDetails() {
      this.getCompanyInfo();
    },
    openDomainValidationPopup() {
      this.domainValidationDialog = true;
    },
    handleDomainValidationDialogClose() {
      this.domainValidationDialog = false;
    },
  },
  beforeDestroy() {
    this.$store.commit("company/setUpdateCompany", null, {
      root: true,
    });
    this.$store.commit("company/setCompanyLogo", null, {
      root: true,
    });
    this.someUniqueKey += 1;
  },
  // Inside your Vue.js component
  watch: {
    "brandingInfo.styled_sidemenu": {
      handler(newVal) {
        if (!newVal) {
          this.brandingInfo.sidemenu_background = "";
          this.brandingInfo.sidemenu_text = "";
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-switch__label {
  font-size: 12px;
  font-weight: 400;
}
::v-deep .el-collapse-item__header {
  color: #9299b8 !important;
}
.deleteIcon {
  width: 10px;
}

.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}

.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}

.branding-page {
  .el-tabs__header {
    margin: 0 1%;
  }

  .each-field {
    display: grid;
    grid-template-columns: 1fr 50px;
    align-items: flex-end;
    font-size: 12px;
  }

  .el-textarea__inner,
  .el-input__inner {
    background-color: #f5f8fa;
  }
}

.sidemenu {
  height: 630px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: thin;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.logo-container {
  display: flex;
  flex-direction: column;

  .el-button {
    margin-left: 15px;
    margin-top: 8px;
    width: 150px !important;
  }
}

.company-details {
  display: flex;
}

.btn-colors {
  display: flex;
}

.btn-colors-2 {
  display: flex;
}

.color-box {
  display: flex;
  flex-direction: column;
}

.logo-name-sty {
  color: #9299b8;
}

.profile-card {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1);
}
@media (min-width: 280px) {
  .btn-colors-2 {
    display: block;
  }
  .btn-colors {
    display: block;
  }
  .color-box {
    margin-left: 12px;
  }
}
</style>
<style scoped>
.domain-validate-button {
  height: 40px;
  margin-top: 2%;
  padding-left: 1%;
  padding-right: 1%;
}
</style>
<style>
.ankaCropper__navButton,
.ankaCropper__saveButton {
  border-radius: 3px;
  display: inline !important;
  height: 16px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.15),
    1px 1px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.ankaCropper__saveButton {
  float: inherit !important;
  padding: 8px 20px;
  font-size: 14px;
}
/* .el-button--primary.is-plain {
  color: var(--primary-color) !important;
  background: var(--primary-contrast-color) !important;
  border-color: var(--primary-color) !important;
} */

/* .el-button--primary:hover {
  color: var(--primary-contrast-color) !important;
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
} */
.color-box {
  position: relative;
  display: inline-block;
}

.color-box .tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 55%;
  left: 70%;
  margin-left: -100px;
  transition: opacity 0.3s;
}

.color-box .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.color-box:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
